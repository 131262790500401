<template>
  <div class="question-store container">
    <YForm v-model="tableForm" inline class="y-form" size="medium">
      <headerBar />
      <YField
        size="medium"
        prefix-icon="el-icon-search"
        name="title"
        placeholder="请输入题库名称"
      ></YField>
      <YButton do="search" class="btn btn_blue"></YButton>
      <YButton class="btn btn_blue" @click="add">
        <i class="el-icon-plus el-icon--left" />
        新建题库
      </YButton>

      <div class="operate">
        <el-link
          type="info"
          underline
          @click="deleteQuestionStore()"
          class="mr8"
        >
          <i class="el-icon-delete"></i>
          删除题库
        </el-link>
      </div>
      <YQueryTable
        class="table"
        tooltip-effect="dark"
        ref="queryTable"
        :serve="getList"
        @selection-change="handleSelection"
      >
        <template>
          <el-table-column type="selection" />
          <el-table-column
            label="题库名称"
            prop="title"
            show-overflow-tooltip
            min-width="180"
          />
          <el-table-column
            label="题库类别"
            prop="typeName"
            min-width="120"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            label="题目数量"
            prop="quNumber"
            min-width="120"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            label="创建人"
            prop="userName"
            min-width="120"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            label="修改时间"
            prop="updateTime"
            min-width="120"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column label="操作" prop="name" min-width="240">
            <template slot-scope="scope">
              <el-link class="mr8" @click="edit(scope.row)">编辑</el-link>
              <el-link
                class="mr8"
                @click="jumpTo(`/questionList?id=${scope.row.id}`)"
                >试题管理</el-link
              >
              <el-link
                @click="deleteQuestionStore([scope.row.id])"
                type="danger"
                >删除</el-link
              >
            </template>
          </el-table-column>
        </template>
      </YQueryTable>
    </YForm>
    <el-dialog
      class="dialog_wrapper"
      :title="isEdit ? '编辑题库' : '新建题库'"
      :visible.sync="dialogShow"
      width="450px"
    >
      <YForm
        v-model="formValues"
        ref="addForm"
        label-position="top"
        @submit="saveForm"
      >
        <YField
          label="题库名称"
          name="title"
          :maxlength="15"
          show-word-limit
          :rules="['required', 'whiteSpace']"
        />
        <YField
          label="题库分类"
          name="typeId"
          component="el-cascader"
          :options="typeTree"
          :rules="['required']"
          :props="{
            label: 'typeName',
            checkStrictly: true,
            emitPath: false,
            value: 'id',
          }"
        />
        <div class="dialog-footer">
          <YButton class="btn btn_blue" do="submit" />
          <YButton class="btn btn_cancle btn_normal" @click="dialogShow = false"
            >取消</YButton
          >
        </div>
      </YForm>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchQuestionStoreList,
  fetchQuestionStoreTypeList,
  saveQuestionStore,
  deleteQuestionStore,
} from "@/apis/backStageEndAPI/examStageManagement/index.js";
import { deleteRequest, arr2tree } from "@/utils/index.js";
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";

export default {
  data() {
    return {
      tableForm: {},
      formValues: {},
      selectedRows: [],
      typeList: null,
      typeTree: null,
      isEdit: false,
      dialogShow: false,
    };
  },
  components: { headerBar },
  methods: {
    clearValidate(dom) {
      dom = dom || this.$refs.addForm;
      dom && this.$nextTick(dom.clearValidate);
    },
    async getQuestionType() {
      const { data } = await fetchQuestionStoreTypeList({});
      this.typeList = data;
      const tree = arr2tree(data);
      this.typeTree = tree;
      return {
        data: tree,
      };
    },

    jumpTo(url) {
      this.$router.push(url);
    },

    async deleteQuestionStore(ids) {
      ids = ids || this.selectedRows.map((e) => e.id);
      await deleteRequest(deleteQuestionStore, ids);
      this.$refs.queryTable.runServe();
    },
    add() {
      if (!this.typeList) {
        this.getQuestionType();
      }
      this.formValues = {};
      this.clearValidate();
      this.isEdit = false;
      this.dialogShow = true;
    },

    async edit(row) {
      if (!this.typeList) {
        this.getQuestionType();
      }
      this.isEdit = true;
      this.formValues = {
        ...row,
      };
      this.clearValidate();
      this.dialogShow = true;
    },

    async saveForm() {
      try {
        const { typeId } = this.formValues;
        this.formValues.typeName = this.typeList.find(
          (e) => e.id === typeId
        )?.typeName;
        delete this.formValues.createTime;
        delete this.formValues.updateTime;
        await saveQuestionStore({
          ...this.formValues,
        });
        this.$refs.queryTable.runServe({ currentPage: 1 });
      } finally {
        this.dialogShow = false;
      }
    },

    handleSelection(rows) {
      this.selectedRows = rows;
    },

    async getList({ formValues, params }) {
      const { data } = await fetchQuestionStoreList({
        current: params.currentPage,
        size: params.pageSize,
        params: formValues,
      });
      return {
        data: data?.records,
        total: data?.total,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.operate {
  margin-bottom: 12px;
  text-align: right;
  .el-icon-delete {
    color: #f65160;
  }
}
</style>
